import React, { useEffect, useLayoutEffect } from 'react'
import BackgroundSection from '../Background/BackgroundSection';
import HomeCards from './HomeCards';
import { cleanUpMetaTag, updateMetaTag } from '../../common/metaTagHelper';

function Home() {
  useLayoutEffect(() => {
    document.title = 'Free Sweepstakes to enjoy with Friends and Family - The Sweepstaker';
    updateMetaTag("description", "Enjoy free sweepstakes with friends and family on The Sweepstaker. Create a range of sweepstakes covering sporting events to reality TV. Watch the live draw and share the results instantly.")
    updateMetaTag("keywords", "The Sweepstaker, Free sweepstakes, Sporting sweepstakes");
      return () => {
        cleanUpMetaTag();
      }
   }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  sessionStorage.removeItem('nextPath');
  sessionStorage.removeItem('sweepstakePairings');
  sessionStorage.removeItem('sweepstakeTheme');
    return (
        <>
          <BackgroundSection childComponent={<HomeCards/>}/>
        </>
    );
}

export default Home
