import { React } from 'react';
import './displaysweepstakepairing.css';
import { useNavigate } from 'react-router-dom';
import { getNextPathname } from '../../common/constants';
import PulseLoader from 'react-spinners/PulseLoader'
import { CustomButton } from '../Button/CustomButton';

function DisplaySweepstakePairing({
    lightText,
    team,
    person,
    resetPerson,
    pairings
  }) {
    let navigate = useNavigate(); 

    const routeChange = () =>{ 
      const nextPath = getNextPathname("DISPLAY_RESULTS");
      sessionStorage.setItem('nextPath', nextPath);
      navigate(nextPath, {state:pairings});
    }

    const GetPulseLoader = () => {
      return resetPerson ? <PulseLoader className='pulseLoader' color="#004080" size={25} loading/> : <PulseLoader className='pulseLoader' color="#004080" size={25} speedMultiplier={0} loading/>;
    }

    return (
      <div className='display-match-wrapper'>
          <div className='display-match-section'>
            <div className='teamWrapper'>
                <h1 className={lightText ? 'headingDisplayMatch team' : 'headingDisplayMatch dark team'}>
                  {team}
                </h1>
              </div>
              <div className='loadingWrapper'>
                  <GetPulseLoader/>
              </div>
              <div className='personWrapper'>
                <div className={resetPerson ? 'hide__person' : 'show__person' }>
                  <h1 className={lightText ? 'headingDisplayMatch person' : 'headingDisplayMatch dark person'}>
                    {person}
                  </h1>
                </div>
              </div>
            </div>
          <div className='skip-results'>
              <CustomButton buttonSize='btn--medium' buttonColor='clear' buttonStyle='btn--border' testId={"skip-button"}  onClick={routeChange}>
                Skip to the results
              </CustomButton>
          </div>
      </div>
    );
  }
  

export default DisplaySweepstakePairing;
