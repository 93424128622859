import FormulaOneImage from '../../assets/formula-1-drivers.jpg'
import { USER_EVENTS } from '../../stateMachine/StateMachine'

export const f1SeasonConstructors2024Constants = {
    lightText: true,
    topLine: 'F1 Constructors World Championship 2024 sweepstake',
    headline: 'F1 Constructors World Championship 2024',
    subText: "Which F1 team will win the 2024 Constructor's Championship?",
    metaDescription: "Look forward to another exciting F1 season by starting a free F1 Constructors sweepstake with The Sweepstaker. Predict which team will come out on top and join the fun with friends and family!",
    metaKeywords: "F1 Constructors championship 2024, F1, F1 constructors, sweepstake, free, Formula One sweepstake, The Sweepstaker",
    sweepstakeSelectedEvent: USER_EVENTS.F1_CONSTRUCTORS_2024_SELECTED,
    startDate: '2024/03/02',
    endDate: '2024/12/08',
    img: FormulaOneImage,
    category: "formulaOne",
    alt: 'Three Formula One drivers racing',
    items: [
    "Red Bull Racing",
    "Ferrari",
    "Mercedes",
    "Alpine",
    "McLaren",
    "Kick Sauber",
    "Haas",
    "Aston Martin",
    "RB",
    "Williams"
    ],
}