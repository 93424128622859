import React, { useLayoutEffect } from 'react';
import "./terms.css";
import { cleanUpMetaTag, updateMetaTag } from '../../common/metaTagHelper';

export default function Terms() {

    useLayoutEffect(() => {
        document.title = 'Terms and Conditions - The Sweepstaker';
        updateMetaTag("description", "Terms and Conditions for the Sweepstaker")
        updateMetaTag("keywords", "Terms and Conditions, Terms, Terms of use, The Sweepstaker");
          return () => {
            cleanUpMetaTag();
          }
       }, []);
  
    return (
                <div className='terms'>
                    <h1 className='termsHeader'>Terms and Conditions</h1>
                    <div className='terms__container'>
                        <div className='terms__section'>
                            <h2 className='termsSubHeader'>1. General Information Disclaimer</h2>
                            <p>The information provided on this website is for general informational purposes only and may change without prior notice.</p>
                        </div>
                        <div className='terms__section'>
                            <h2 className='termsSubHeader'>2. Agreement to Terms</h2>
                            <p>Your use of this website constitutes your agreement to comply with and be bound by the terms and conditions set forth herein.</p>
                        </div>
                        <div className='terms__section'>
                            <h2 className='termsSubHeader'>3. Nature of the Service</h2>
                            <p>The Sweepstaker is not an organiser of sweepstakes, but a platform for sweepstakes to be conducted for free. The Sweepstaker requires no fee and does not allocate prizes for any sweepstake. Users utilising our platform for facilitating sweepstakes must ensure compliance with the Gambling Act 2005.</p>
                        </div>
                        <div className='terms__section'>
                            <h2 className='termsSubHeader'>4. Limitation of Liability</h2>
                            <p>We do not guarantee the accuracy, timeliness, or completeness of the information provided on this website. We are not liable for any inaccuracies or errors in the information provided.</p>
                        </div>
                        <div className='terms__section'>
                            <h2 className='termsSubHeader'>5. Intellectual Property Rights</h2>
                            <p>The content of this website, including its design, layout, graphics, and other materials, is owned or licensed to us. Reproduction of any material from this website is prohibited without prior written consent.</p>
                        </div>
                        <div className='terms__section'>
                            <h2 className='termsSubHeader'>6. Cookie Policy</h2>
                            <p>We use cookies on this website to monitor browsing preferences. For more information, please refer to our <a href="/cookies">Cookie Policy</a>.</p>
                        </div>
                        <div className='terms__section'>
                            <h2 className='termsSubHeader'>7. External Links Disclaimer</h2>
                            <p>This website may contain links to external websites for additional information. We do not endorse these websites and are not responsible for their content.</p>
                        </div>
                        <div className='terms__section'>
                            <h2 className='termsSubHeader'>8. Governing Law</h2>
                            <p>Any disputes arising from your use of this website shall be governed by the laws of England, Northern Ireland, Scotland, and Wales.</p>
                        </div>
                    </div>
                </div>
      );
}