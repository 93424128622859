import { stateMachine } from "../stateMachine/StateMachine";

export function getNextPathname(event) {
    const currentPathname = window.location.pathname;

    const path = currentPathname.replace(/\/$/, "");
    
    const nextPathname = stateMachine
      .transition(path, event);
    return nextPathname.value;
  }