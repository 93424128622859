

export default function createPairings(sweepstakeTheme, people) {
    const items = sweepstakeTheme.items;
    const shuffledPeople = shuffleArray(people);
    const shuffledItems = shuffleArray(items);
    const output = makePairings(shuffledPeople, shuffledItems);
    const suffledOutput = shuffleArray(output);
    sessionStorage.setItem('sweepstakePairings', JSON.stringify(suffledOutput));
    return {'pairings': suffledOutput};
}

function shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }
  
export function makePairings(people, items) {
    let numberOfPeople = people.length;
    let numberOfItems = items.length;
    let pairs = [];
    let newIndex = 0;
    let numberOfItemsPerPerson = Math.floor(numberOfItems / numberOfPeople);
    
    people.forEach((person) => {
        for (let index = 0; index < numberOfItemsPerPerson; index++) {
          pairs.push(  
            {
            'person': person.text,
            'team': items[newIndex]
            }
        )
        newIndex = newIndex + 1;
      }
      });
      
      let remainderIndex = 0;
      people.forEach((person) => {
        if (remainderIndex <  numberOfItems % numberOfPeople) {
            pairs.push(  
              {
              'person': person.text,
              'team': items[newIndex]
              }
          )
        } else {
          return;
        }
        remainderIndex = remainderIndex + 1;
        newIndex = newIndex + 1;
      });
      return pairs;
  }