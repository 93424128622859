import { createMachine } from 'xstate';
import { PATH_NAMES } from "../app.constants";

export const USER_EVENTS = {
  ENTER_NAMES: "ENTER_NAMES",
  NUMBER_OF_PARTICIPANTS_SELECTED: "NUMBER_OF_PARTICIPANTS_SELECTED",
  START_DRAW: "START_DRAW",
  DISPLAY_RESULTS: "DISPLAY_RESULTS",
  F1_DRIVERS_2024_SELECTED: "F1_DRIVERS_2024_SELECTED",
  F1_CONSTRUCTORS_2024_SELECTED: "F1_CONSTRUCTORS_2024_SELECTED",
  EURO_2024_SELECTED: "EURO_2024_SELECTED",
  TOUR_DE_FRANCE_2024_SELECTED: "TOUR_DE_FRANCE_2024_SELECTED",
  T20_WOMENS_WORLD_CUP_SELECTED: "T20_WOMENS_WORLD_CUP_SELECTED",
  JOSHUA_DUBOIS_SELECTED: "JOSHUA_DUBOIS_SELECTED",
  BROWSE_SWEEPSTAKES: "BROWSE_SWEEPSTAKES",
  FEEDBACK_GIVEN: "FEEDBACK_GIVEN"
};

const createSweepstakesTransitions = (events, basePath) => {
  const transitions = {};
  events.forEach(event => {
    transitions[event.eventName] = { target: `${basePath}/${event.path}` };
  });
  return transitions;
};

const feedbackTransition = {
  [USER_EVENTS.FEEDBACK_GIVEN]: { target: PATH_NAMES.THANKS }
};

const sweepstakesEvents = [
  { eventName: USER_EVENTS.EURO_2024_SELECTED, path: PATH_NAMES.EURO_2024 },
  { eventName: USER_EVENTS.T20_WOMENS_WORLD_CUP_SELECTED, path: PATH_NAMES.WOMENS_T20_WORLD_CUP_2024 },
  { eventName: USER_EVENTS.TOUR_DE_FRANCE_2024_SELECTED, path: PATH_NAMES.TOUR_DE_FRANCE_2024 },
  { eventName: USER_EVENTS.F1_DRIVERS_2024_SELECTED, path: PATH_NAMES.FORMULA_ONE_DRIVERS_2024 },
  { eventName: USER_EVENTS.F1_CONSTRUCTORS_2024_SELECTED, path: PATH_NAMES.FORMULA_ONE_CONSTRUCTORS_2024 },
  { eventName: USER_EVENTS.JOSHUA_DUBOIS_SELECTED, path: PATH_NAMES.JOSHUA_DUBOIS_BOXING },
];

const createStateMachineStates = () => {
  const states = {
    [PATH_NAMES.HOME]: {
      on: {
        ...createSweepstakesTransitions(sweepstakesEvents, PATH_NAMES.SWEEPSTAKES),
        [USER_EVENTS.BROWSE_SWEEPSTAKES]: { target: PATH_NAMES.SWEEPSTAKES }
      }
    },
    [PATH_NAMES.SWEEPSTAKES]: {
      on: createSweepstakesTransitions(sweepstakesEvents, PATH_NAMES.SWEEPSTAKES)
    }
  };

  sweepstakesEvents.forEach(event => {
    const basePath = `${PATH_NAMES.SWEEPSTAKES}/${event.path}`;
    states[basePath] = {
      on: {
        [USER_EVENTS.NUMBER_OF_PARTICIPANTS_SELECTED]: { target: `${basePath}/${PATH_NAMES.ENTER_SWEEPERS}` }
      }
    };
    states[`${basePath}/${PATH_NAMES.ENTER_SWEEPERS}`] = {
      on: {
        [USER_EVENTS.START_DRAW]: { target: `${basePath}/${PATH_NAMES.LIVE_DRAW}` },
        [USER_EVENTS.DISPLAY_RESULTS]: { target: `${basePath}/${PATH_NAMES.DISPLAY_RESULTS}` },
      }
    };
    states[`${basePath}/${PATH_NAMES.LIVE_DRAW}`] = {
      on: {
        [USER_EVENTS.DISPLAY_RESULTS]: { target: `${basePath}/${PATH_NAMES.DISPLAY_RESULTS}` }
      }
    };
    states[`${basePath}/${PATH_NAMES.DISPLAY_RESULTS}`] = {
      on: feedbackTransition
    };
  });

  states[PATH_NAMES.THANKS] = {
    type: 'final'
  };

  return states;
};

export const stateMachine = createMachine({
  predictableActionArguments: true,
  initial: PATH_NAMES.HOME,
  states: createStateMachineStates()
});
