import { f1SeasonConstructors2024Constants } from "./formulaOne/seasonConstructors2024Constants";
import { f1SeasonDrivers2024Constants } from "./formulaOne/seasonDrivers2024Constants";
import { PATH_NAMES } from "../app.constants";
import { womensT20WorldCup2024Constants } from "./cricket/womensT20WorldCup2024Constants";
import { anthonyJoshuaDanielDuboisConstants } from "./boxing/anthonyJoshuaDanielDubois";

 export const supportedSweepstakes = [
     {key: PATH_NAMES.FORMULA_ONE_DRIVERS_2024, value: f1SeasonDrivers2024Constants},
     {key: PATH_NAMES.FORMULA_ONE_CONSTRUCTORS_2024, value: f1SeasonConstructors2024Constants},
      {key: PATH_NAMES.JOSHUA_DUBOIS_BOXING, value: anthonyJoshuaDanielDuboisConstants},
      {key: PATH_NAMES.WOMENS_T20_WORLD_CUP_2024, value: womensT20WorldCup2024Constants}
 ];