import React, { useLayoutEffect } from 'react'
import "./entersweepers.css";
import SweeperForm from './SweeperForm';

export default function EnterSweepers() {
    useLayoutEffect(() => {
        document.title = 'Enter names - The Sweepstaker';
       }, []);

  return (
    <div className='nameFormWrapper'>
        <SweeperForm/>
    </div>
  )
}