import React, { useLayoutEffect } from 'react';
import "./sweepstakes.css";
import { useParams } from 'react-router-dom';
import { supportedSweepstakes } from '../../constants/supportedSweepstakes';
import EnterNumber from './EnterNumber';
import { cleanUpMetaTag, updateMetaTag } from '../../common/metaTagHelper';

export default function Sweepstakes() {
    const { sweepstakeName } = useParams();
    let sweepstakeTheme = supportedSweepstakes.find(name => name.key === sweepstakeName)

    if (!sweepstakeTheme) {
        throw new Error('Sweepstake theme is invalid');
      }

      const maxNumberOfParticipants = sweepstakeTheme.value.items.length;

    useLayoutEffect(() => {
      const sweepstakeTitle = sweepstakeTheme.value.topLine + ' - The Sweepstaker';
      const metaDescription = sweepstakeTheme.value.metaDescription;
      const metaKeywords = sweepstakeTheme.value.metaKeywords;
      document.title = sweepstakeTitle;
      updateMetaTag("description", metaDescription)
      updateMetaTag("keywords", metaKeywords);
        return () => {
          cleanUpMetaTag();
        }
     }, [sweepstakeTheme.value]);
  
    return (
      <div className='participantsWrapper'>
        <div className='participants'>
          <h1 className='header__participants'>{sweepstakeTheme.value.headline} FREE Sweepstake</h1>
          <h2 className='subheader__participants'>{sweepstakeTheme.value.subText}</h2>
          <div className="buttonParticipantsWrapper">
            <h3 className='participantsText'>Please enter the number of participants for the {sweepstakeTheme.value.topLine}.</h3>
            <p className='participantsText'>The draw will be divided as evenly as possible between participants.</p>
            <label className='participantsLabel' htmlFor='number-of-participants'>How many people are entering this Sweepstake?</label>
            <p className='participantsHintText'>Enter a number between 2 and {maxNumberOfParticipants}</p>
            <EnterNumber sweepstakeTheme={sweepstakeTheme.value} sweepstakeName={sweepstakeName}/>
          </div>
        </div>
      </div>
      );
}