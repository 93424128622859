import React, { useLayoutEffect } from 'react';
import "./privacy.css";
import { cleanUpMetaTag, updateMetaTag } from '../../common/metaTagHelper';

export default function Privacy() {

    useLayoutEffect(() => {
        document.title = 'Privacy - The Sweepstaker';
        updateMetaTag("description", "The Sweepstaker's Privacy Policy")
        updateMetaTag("keywords", "Privacy policy, Privacy, The Sweepstaker");
          return () => {
            cleanUpMetaTag();
          }
       }, []);
  
    return (
                <div className='privacy'>
                    <h1 className='privacyHeader'>Privacy Policy</h1>
                    <div className='privacy__container'>
                        <div className='privacy__section'>
                            <h2 className='privacySubHeader'>1. Introduction</h2>
                            <p>Our Privacy Policy is here to explain how we process and use your data.</p>
                        </div>
                        <div className='privacy__section'>
                            <h2 className='privacySubHeader'>2. Identifying Purposes</h2>
                            <p>We collect, use and disclose Personal Information to provide you with the product or service you have requested and to offer you additional products and services we believe you might be interested in. The purposes for which we collect Personal Information will be identified before or at the time we collect the information.</p>
                        </div>
                        <div className='privacy__section'>
                            <h2 className='privacySubHeader'>3. Consent</h2>
                            <p>Knowledge and consent are required for the collection, use or disclosure of Personal Information except where required or permitted by law. Providing us with your Personal Information is always your choice. However, your decision not to provide certain information may limit our ability to provide you with our services. We will not require you to consent to the collection, use, or disclosure of information as a condition to the supply of a service, except as required to be able to supply the service.</p>
                        </div>
                        <div className='privacy__section'>
                            <h2 className='privacySubHeader'>4. Limiting Collection</h2>
                            <p>The Personal Information collected will be limited to those details necessary for the purposes identified by us.</p>
                        </div>
                        <div className='privacy__section'>
                            <h2 className='privacySubHeader'>5. Limiting Use, Disclosure and Retention</h2>
                            <p>Personal Information may only be used or disclosed for the purpose for which it was collected unless you have otherwise consented, or when it is required or permitted by law. Personal Information will only be retained for the period of time required to fulfill the purpose for which we collected it or as may be required by law.</p>
                        </div>
                        <div className='privacy__section'>
                            <h2 className='privacySubHeader'>6. Accuracy</h2>
                            <p>Personal Information will be maintained in as accurate, complete and up-to-date form as is necessary to fulfill the purposes for which it is to be used.</p>
                        </div>
                        <div className='privacy__section'>
                            <h2 className='privacySubHeader'>7. Safeguarding Customer Information</h2>
                            <p>Personal Information will be protected by security safeguards that are appropriate to the sensitivity level of the information. We take all reasonable precautions to protect your Personal Information from any loss or unauthorized use, access or disclosure.</p>
                        </div>
                        <div className='privacy__section'>
                            <h2 className='privacySubHeader'>8. Openness</h2>
                            <p>We will make information available to you about our policies and practices with respect to the management of your Personal Information.</p>
                        </div>
                        <div className='privacy__section'>
                            <h2 className='privacySubHeader'>9. Customer Access</h2>
                            <p>Upon request, you will be informed of the existence, use and disclosure of your Personal Information, and will be given access to it. You may verify the accuracy and completeness of your Personal Information, and may request that it be amended, if appropriate. However, in certain circumstances permitted by law, we will not disclose certain information to you. For example, we may not disclose information relating to you if other individuals are referenced or if there are legal, security or commercial proprietary restrictions.</p>
                        </div>
                        <div className='privacy__section'>
                            <h2 className='privacySubHeader'>10. Cookies</h2>
                            <p>A cookie is a small computer file or piece of information that may be stored in your computer's hard drive when you visit our websites. We may use cookies to improve our website’s functionality and in some cases, to provide visitors with a customized online experience.</p>
                            <p>Please view our <a href="/cookies">Cookie Policy</a> for more information on our use of Cookies</p>
                        </div>
                        <div className='privacy__section'>
                            <h2 className='privacySubHeader'>11. Other Websites</h2>
                            <p>Our website may contain links to other third party sites that are not governed by this privacy policy. Although we endeavour to only link to sites with high privacy standards, our privacy policy will no longer apply once you leave our website. Additionally, we are not responsible for the privacy practices employed by third party websites. Therefore, we suggest that you examine the privacy statements of those sites to learn how your information may be collected, used, shared and disclosed.</p>
                        </div>
                    </div>
                </div>
      );
}