import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getNextPathname } from '../../common/constants';
import { FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa';
import './displayresults.css';


function Feedback({sweepstakeTheme}) { 

    let navigate = useNavigate();

    const thumbClick = (thumb) => {
        window.giveThumbFeedback(thumb, sweepstakeTheme.headline);
        const nextPath = getNextPathname("FEEDBACK_GIVEN");
        sessionStorage.setItem('nextPath', nextPath);
        navigate(nextPath);
      }

    return (
        <>
            <div className='feedbackSection'>
                <h2>How was your experience using The Sweepstaker today?</h2>
                <button className='feedbackButton'>
                    <FaRegThumbsUp size={25} onClick={() => thumbClick('thumbsUp')}/>
                </button>
                <div className="divider"/>
                <button className='feedbackButton'>
                    <FaRegThumbsDown size={25} onClick={() => thumbClick('thumbsDown')}/>
                </button>
              </div>
        </>
    )
}

export default Feedback;
