import BoxingRing from '../../assets/boxing-ring.jpg'
import { USER_EVENTS } from '../../stateMachine/StateMachine'

export const anthonyJoshuaDanielDuboisConstants = {
    lightText: true,
    topLine: 'Anthony Joshua vs Daniel Dubois Sweepstake',
    headline: 'Anthony Joshua vs Daniel Dubois',
    subText: 'Who will claim the winning round?',
    metaDescription: "Get ready for the Anthony Joshua vs. Daniel Dubois fight on September 21st with a free sweepstake from The Sweepstaker! Join friends and family in predicting which round the fight will be won. Start your Anthony Joshua vs. Daniel Dubois sweepstake now and enjoy the excitement!",
    metaKeywords: "Anthony Joshua vs Daniel Dubois, Boxing, AJ vs Dubois, sweepstake, free, The Sweepstaker",
    sweepstakeSelectedEvent: USER_EVENTS.JOSHUA_DUBOIS_SELECTED,
    startDate: '2024/09/21',
    endDate: '2024/09/21',
    img: BoxingRing,
    category: "boxing",
    alt: 'Boxing Ring',
    items: [
        "Joshua win - Round 1",
        "Joshua win - Round 2",
        "Joshua win - Round 3",
        "Joshua win - Round 4",
        "Joshua win - Round 5",
        "Joshua win - Round 6",
        "Joshua win - Round 7",
        "Joshua win - Round 8",
        "Joshua win - Round 9",
        "Joshua win - Round 10",
        "Joshua win - Round 11",
        "Joshua win - Round 12",
        "Joshua win - Decision or Technical Decision",
        "Dubois win - Round 1",
        "Dubois win - Round 2",
        "Dubois win - Round 3",
        "Dubois win - Round 4",
        "Dubois win - Round 5",
        "Dubois win - Round 6",
        "Dubois win - Round 7",
        "Dubois win - Round 8",
        "Dubois win - Round 9",
        "Dubois win - Round 10",
        "Dubois win - Round 11",
        "Dubois win - Round 12",
        "Dubois win - Decision or Technical Decision",
        "Draw"
    ]
}