import React, { useLayoutEffect } from 'react';
import './cards.css';
import CardItem from './CardItem';
import { supportedSweepstakes } from '../../constants/supportedSweepstakes';
import { cleanUpMetaTag, updateMetaTag } from '../../common/metaTagHelper';

function Browse() {


  let cardItems = supportedSweepstakes.map((supportedSweepstake, i) => {
    return (
      <CardItem {...supportedSweepstake.value} sweepstake={supportedSweepstake.key} key={i}/>
    );
  })

  useLayoutEffect(() => {
    document.title = 'Explore all Sweepstakes - The Sweepstaker';
    updateMetaTag("description", "Discover and join our free sweepstakes at The Sweepstaker. Choose a sweepstake, enter participant names, watch live draws, and share the excitement with friends and family.")
    updateMetaTag("keywords", "Explore, Our sweepstakes, The Sweepstaker, Sporting sweepstakes, TV sweepstakes, football sweepstakes, formula one sweepstakes, formula 1 sweepstakes, cricket sweepstakes");
      return () => {
        cleanUpMetaTag();
      }
   }, []);


    return (
      <>
        <div className='cards'>
          <div className='cards__container'>
              <h1 className='cardsListHeading'>Explore all Sweepstakes</h1>
              <h2 className='cardsListSubheading'>Select a Sweepstake and get started!</h2>
              <div className='cards__wrapper'>
                  <ul className='cards__items'>
                  {cardItems}
                  </ul>
              </div>
          </div>
        </div>
      </>
    );
  }
  

export default Browse;
