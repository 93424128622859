
  export function updateMetaTag(name, content) {
    let metaTag = document.querySelector(`meta[name="${name}"]`);
    if (!metaTag) {
      metaTag = document.createElement('meta');
      metaTag.setAttribute('name', name);
      document.head.appendChild(metaTag);
    }
    metaTag.setAttribute('content', content);
  }

  export function cleanUpMetaTag() {
    document.title = '';

    const metaTags = ['description', 'keywords', 'author', 'robots'];
    metaTags.forEach(name => {
      const metaTag = document.querySelector(`meta[name="${name}"]`);
      if (metaTag) {
        document.head.removeChild(metaTag);
      }
    });
  }