import React, { useLayoutEffect } from 'react';
import "./about.css";
import { cleanUpMetaTag, updateMetaTag } from '../../common/metaTagHelper';

export default function About() {

     useLayoutEffect(() => {
        document.title = 'About us - The Sweepstaker';
        updateMetaTag("description", "Learn more about what we do at The Sweepstake and how it all started")
        updateMetaTag("keywords", "About Us, About The Sweepstaker, Our Story");
          return () => {
            cleanUpMetaTag();
          }
       }, []);
  
    return (
                <div className='about'>
                    <h1 className='aboutHeader'>About Us</h1>
                    <h2 className='aboutSubheader'>Welcome to The Sweepstaker!</h2>
                    <div className='about__container'>
                        <p>At The Sweepstaker, we provide a selection of free Sweepstakes that are simple to organise and easy to share.</p>
                        <p>Just pick a sweepstake and select the number of participants, then watch the live draw or or skip to the results before sharing with friends and family.</p>
                        <p>We're always keen to improve and enhance your experience of The Sweepstaker.</p>
                        <p>Thank you for choosing The Sweepstaker!</p>
                    </div>
                </div>
      );
}