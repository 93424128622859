import React, { useLayoutEffect } from 'react';
import "./thanks.css";
import { CustomButton } from '../Button/CustomButton';
import { PATH_NAMES } from '../../app.constants';
import { useNavigate } from 'react-router-dom';
import { cleanUpMetaTag, updateMetaTag } from '../../common/metaTagHelper';

export default function Thanks() {

    let navigate = useNavigate();

    const routeChange = () => {
      navigate(PATH_NAMES.HOME);
    }  

     useLayoutEffect(() => {
      document.title = 'Thanks for your feedback - The Sweepstaker';
      updateMetaTag("robots", "noindex, nofollow")
        return () => {
          cleanUpMetaTag();
        }
     }, []);
  
    return (
                <div className='thanks'>
                    <h1 className='thanksHeader'>Thanks for your feedback</h1>
                    <div className='thanks__container'>
                        <p>We are always working to improve your experience.</p>
                    </div>
                    <div className='displayResultsButton'>
                      <CustomButton buttonSize='btn--wide' buttonColor='clear' buttonStyle='btn--border' onClick={routeChange} testId={"home-button"} >
                        Home
                      </CustomButton>
                  </div>
                </div>
      );
}