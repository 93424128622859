import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';

function Footer({closeMobileMenu}) {

  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <small className='website-rights'>© 2024 The Sweepstaker</small>
          </div>
          <div className='footer-link-items'>
            <Link to="/cookies" onClick={closeMobileMenu}>Cookies</Link>
          </div>
          <div className='footer-link-items'>
            <Link to="/privacy" onClick={closeMobileMenu}>Privacy</Link>
          </div>
          <div className='footer-link-items'>
            <Link to="/terms" onClick={closeMobileMenu}>Terms and Conditions</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;