import  React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getNextPathname } from '../../common/constants';
import { JourneyContext } from '../../JourneyContext';
import { SubheaderCardText } from '../../helpers/SubheaderCardText';

function CardItem({
    img,
    alt,
    headline,
    sweepstake,
    startDate,
    endDate,
    sweepstakeSelectedEvent
  }) {
    const [, setSweepstakeTheme] = useContext(JourneyContext);
    let navigate = useNavigate(); 

    const routeChange = () =>{ 
      window.sweepstakeSelected(headline, window.location.pathname);
      setSweepstakeTheme(sweepstake);
      const nextPath = getNextPathname(sweepstakeSelectedEvent);
      sessionStorage.setItem('nextPath', nextPath);
      navigate(nextPath);
    }
  return (
    <>
      <li className='cards__item'>
      <button onClick={routeChange} className="cards__item__link">
          <figure className='cards__item__pic-wrap'>
            <img
              className='cards__item__img'
              alt={alt}
              src={img}
              loading="lazy"
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{headline}</h5>
            <p className='cards__subheader__text'>{SubheaderCardText(startDate, endDate)}</p>
          </div>
        </button>
      </li>
    </>
  );
}

export default CardItem;