import React, {useEffect, useState } from 'react';
import StartSection from '../StartSection/StartSection';
import { useNavigate } from 'react-router-dom';
import { getNextPathname } from '../../common/constants';
import DisplaySweepstakePairing from './DisplaySweepstakePairing';

const GeneratePairings = (props) =>  {

  let navigate = useNavigate();
  const [resetPerson, setResetPerson] = useState(true);
  const [displayPerson, setDisplayPerson] = useState(null);
  const [displayTeam, setDisplayTeam] = useState(null);
  const [finished, setFinished] = useState(false);
  const [timeFinished, setTimerFinished] = useState(false);
  const sweepstakes = props.sweepstakes.pairings;
  const timeBetweenDraw = 3000;

  const delay = (ms) =>
  new Promise((res) => {
    setTimeout(() => {
      res();
    }, ms);
  });

  const Timer = ({ seconds }) => {
    // initialize timeLeft with the seconds prop
    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(() => {
      // exit early when we reach 0
      if (!timeLeft) {
        setTimerFinished(true);
        return;
      }

      // save intervalId to clear the interval when the
      // component re-renders
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
      // add timeLeft as a dependency to re-rerun the effect
      // when we update it
    }, [timeLeft]);

    return timeLeft;
  };

  useEffect(() => {
    (async function () {
      if(sweepstakes && timeFinished) {
        for (let el of sweepstakes) {
          setDisplayTeam(el.team);
          setResetPerson(true);
          await delay(timeBetweenDraw);
          setDisplayPerson(el.person);
          setResetPerson(false);
          await delay(timeBetweenDraw);
        }
        await delay(timeBetweenDraw);
        setFinished(true);
      }
    })();
  }, [sweepstakes, timeFinished]);
  
  if(finished) {
    const nextPath = getNextPathname("DISPLAY_RESULTS");
    sessionStorage.setItem('nextPath', nextPath);
    navigate(nextPath, {state: sweepstakes});
  }

  if(displayPerson == null && displayTeam == null) {
    return (
      <>
        <StartSection timer={<Timer seconds={3}/>}/>
      </>
    )
  } 
    else {
      const display = {
        lightText: true,
        team: displayTeam,
        resetPerson: resetPerson,
        person: displayPerson,
        pairings: sweepstakes,
    }
      return (
          <DisplaySweepstakePairing {...display} />
      );
    }
}

    export default GeneratePairings;