import React, {useContext, useLayoutEffect } from 'react';
import { JourneyContext } from '../../JourneyContext';
import './displayresults.css';
import Feedback from './Feedback';
import Pairings from './Pairings';
import { cleanUpMetaTag, updateMetaTag } from '../../common/metaTagHelper';

function DisplayResults() {

  const [ sweepstakeTheme ] = useContext(JourneyContext);
  if (!sweepstakeTheme) {
    throw new Error('Sweepstake theme is invalid');
  }

    window.sweepstakeResultsShown(sweepstakeTheme.headline);

     useLayoutEffect(() => {
      document.title = 'Display Results - The Sweepstaker';
      updateMetaTag("robots", "noindex, nofollow")
        return () => {
          cleanUpMetaTag();
        }
     }, []);

    return (
      <>
        <div className='display-all-container'>
          <div className='display-all-section'>
              <h1 className='results-header'>{sweepstakeTheme.headline} Sweepstake Results</h1>
              <h2>Copy these results or share with friends and family.</h2>
              <br/>
              <p>Good luck! Please come back and join us for a range of other sweepstakes we offer!</p>
                <Pairings sweepstakeTheme={sweepstakeTheme}/>
                <Feedback sweepstakeTheme={sweepstakeTheme}/>
          </div>
        </div>
      </>
    );
  }
  

export default DisplayResults;
