import { React } from 'react';
import ScrollToTop from "./scrollToTop";
import {BrowserRouter, Route, Routes, Navigate, useParams} from 'react-router-dom';
import NotFoundComponent from './components/Errors/NotFoundComponent';
import './App.css';
import './scss/custom.scss';
import Home from './components/Home/Home';
import LiveDraw from './components/LiveDraw/LiveDraw';
import {
    PATH_NAMES,
  } from "./app.constants";
import BackgroundSection from './components/Background/BackgroundSection';
import { JourneyContext } from './JourneyContext';
import { useLocalStateSweepstakeWrapper } from './hooks/useSessionStorage';
import About from './components/About/About';
import Browse from './components/BrowseSweepstakes/Browse';
import DisplayResults from './components/DisplayResults/DisplayResults';
import Privacy from './components/Privacy/Privacy';
import Cookies from './components/Cookies/Cookies';
import Thanks from './components/ThanksForFeedback/Thanks';
import Terms from './components/Terms/Terms';
import EnterSweepers from './components/EnterSweepers/EnterSweepers';
import Sweepstakes from './components/Sweepstakes/Sweepstakes';
import { supportedSweepstakes } from './constants/supportedSweepstakes';

function App() {
        const [sweepstakeTheme, setSweepstakeTheme] = useLocalStateSweepstakeWrapper('sweepstakeTheme');
        return (
            <BrowserRouter>
                <ScrollToTop />
                <JourneyContext.Provider value={[sweepstakeTheme, setSweepstakeTheme]}>
                    <Routes>
                        <Route path={PATH_NAMES.HOME} exact element={<Home/>}/>
                        <Route path={PATH_NAMES.SWEEPSTAKES} >
                            <Route index element={<BackgroundSection childComponent={<Browse/>}/>}/>
                            <Route path=":sweepstakeName"> 
                                <Route index element={
                                    <ValidateUrlParameter path={PATH_NAMES.SWEEPSTAKES}>
                                        <BackgroundSection childComponent={<Sweepstakes/>}/>
                                    </ValidateUrlParameter>}/>
                                <Route path={PATH_NAMES.ENTER_SWEEPERS} element={
                                    <ProtectedRoute path={PATH_NAMES.ENTER_SWEEPERS}>
                                        <BackgroundSection childComponent={<EnterSweepers/>}/>
                                    </ProtectedRoute>
                                }/>
                                <Route path={PATH_NAMES.LIVE_DRAW} element={
                                    <ProtectedRoute path={PATH_NAMES.LIVE_DRAW}>
                                        <BackgroundSection childComponent={<LiveDraw/>}/>
                                    </ProtectedRoute>
                                }/>
                                <Route path={PATH_NAMES.DISPLAY_RESULTS} element={
                                    <ProtectedRoute path={PATH_NAMES.DISPLAY_RESULTS}>
                                        <BackgroundSection childComponent={<DisplayResults/>}/>
                                    </ProtectedRoute>
                                }/>
                            </Route>
                        </Route>
                            <Route path={PATH_NAMES.THANKS} element={
                                    <ProtectedRoute path={PATH_NAMES.THANKS}>
                                        <BackgroundSection childComponent={<Thanks/>}/>
                                    </ProtectedRoute>
                                }/>
                            <Route path={PATH_NAMES.ABOUT} element={<BackgroundSection childComponent={<About/>}/>}/>
                            <Route path={PATH_NAMES.PRIVACY} element={<BackgroundSection childComponent={<Privacy/>}/>}/>
                            <Route path={PATH_NAMES.COOKIES} element={<BackgroundSection childComponent={<Cookies/>}/>}/>
                            <Route path={PATH_NAMES.TERMS} element={<BackgroundSection childComponent={<Terms/>}/>}/>
                            <Route path="*" element={<BackgroundSection childComponent={<NotFoundComponent/>}/>}/>
                        </Routes>
                        </JourneyContext.Provider>
                </BrowserRouter>
        )
}

const ProtectedRoute = ({children }) => {
    if ((sessionStorage.getItem('nextPath') && sessionStorage.getItem('nextPath') === window.location.pathname)) {
     return children;
    } else {
        console.log('error');
        return <Navigate to={PATH_NAMES.HOME} replace />;
    }
  };

const ValidateUrlParameter = ({ children }) => {
    const { sweepstakeName } = useParams();
    let sweepstake = supportedSweepstakes.find(name => name.key === sweepstakeName)
        if (sweepstake) {
            return children;
        } else {
            return <BackgroundSection childComponent={<NotFoundComponent/>}/>;
        }
  };

export default App;