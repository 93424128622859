export const SubheaderCardText=(startDateOfEvent, endDateOfEvent) => {
    const oneDay = 24 * 60 * 60 * 1000; 
    var startDate = new Date(startDateOfEvent);
    var endDate = new Date(endDateOfEvent);
    var currentDate = new Date(new Date().toDateString());

    if (endDate < currentDate) {
        return 'This event has finished';
    }
    if (startDate > currentDate) {
        const daysLeft = Math.round(Math.abs((currentDate - startDate) / oneDay));
        return "Starts in " + daysLeft + " days";
    } else if (+startDate === +currentDate) {
      return "This event is starting today";
    } else {
      return "This event has already started";
    }
}