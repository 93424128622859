import React from 'react';
import { useLocation } from 'react-router-dom';
import { FaArrowsAltH } from 'react-icons/fa';
import './displayresults.css';
import Share from './Share';

function Pairings({sweepstakeTheme}) {


    const loc = useLocation();

  const displayedPairings = loc.state.sort((a, b) => {
      const [teamA, teamB] = [a.team.split(' - ')[0], b.team.split(' - ')[0]];

      if (teamA < teamB) return -1;
      if (teamA > teamB) return 1;

      if (sweepstakeTheme.category === 'boxing') {
        const number1 = a.team.match(/\d+/);
        const number2 = b.team.match(/\d+/);

        return number1 - number2;
      }
      return 0;
  })

    const CalculatePairings = () => (
        <div>
            <Share pairings={displayedPairings} sweepstakeTheme={sweepstakeTheme}/>
            <h2 className='resultsSubHeader'>Sweepstake Results</h2>
          { 
            displayedPairings.map((item, i) => {
            return (
            <div className='results-container' key={i}>
              <p className='team-heading'>{item.team}</p>
              <div className='minusWrapper'>
                <FaArrowsAltH className="minusSign"/>
              </div>
              <p className='person-heading'> {item.person}</p>
            </div> )})
          }
        </div>
      );

    return (
        <>
            <CalculatePairings/>
        </>
    )
}

export default Pairings;