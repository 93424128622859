import { useState } from "react";
import { supportedSweepstakes } from "../constants/supportedSweepstakes";

export function useLocalStateSweepstakeWrapper(localItem) {
    const [loc, setState] = useState(sessionStorage.getItem(localItem) === null ? null : getSweepstakeFromTheme(sessionStorage.getItem(localItem)));

    function setLoc(newItem) {
        sessionStorage.setItem(localItem, newItem);
        setState(getSweepstakeFromTheme(newItem));
    }
    return [loc, setLoc];
}

function getSweepstakeFromTheme(sweepstakeTheme) {
    let sweepstake = supportedSweepstakes.find(name => name.key === sweepstakeTheme)
        if (sweepstake) {
            return sweepstake.value;
        } else {
            return null;
        }
    }