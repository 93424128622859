import T20WorldCupImage from '../../assets/mens-t20-world-cup.jpg'
import { USER_EVENTS } from '../../stateMachine/StateMachine'

export const womensT20WorldCup2024Constants = {
    lightText: true,
    topLine: 'Womens Cricket T20 World Cup 2024 sweepstake',
    headline: 'Womens Cricket T20 World Cup 2024',
    subText: "Which team will win the Women's T20 World Cup in Bangladesh?",
    metaDescription: "Join the excitement of the Women’s Cricket T20 World Cup 2024 by running a sweepstake with The Sweepstaker. Compete with friends and family, and enjoy the thrill of the tournament. Start your Women’s Cricket T20 World Cup 2024 sweepstake today!",
    metaKeywords: "Women's Cricket T20 World Cup 2024, cricket, t20, cricket world cup, sweepstake, free, The Sweepstaker",
    sweepstakeSelectedEvent: USER_EVENTS.T20_WOMENS_WORLD_CUP_SELECTED,
    startDate: '2024/10/03',
    endDate: '2024/10/20',
    img: T20WorldCupImage,
    alt: 'Cricket bat, helmet and wickets',
    category: "cricket",
    items: [
        "Australia",
        "Bangladesh",
        "England",
        "India",
        "Ireland",
        "New Zealand",
        "Pakistan",
        "South Africa",
        "Sri Lanka",
        "West Indies"
    ]
}