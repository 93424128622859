import React, { useContext, useReducer, useState } from 'react'
import {useLocation} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../Button/CustomButton';
import { getNextPathname } from '../../common/constants';
import { JourneyContext } from '../../JourneyContext';
import "./entersweepers.css";
import createPairings from '../../common/createPairingsHelper';


export default function SweeperForm() { 
    const [ sweepstakeTheme ] = useContext(JourneyContext);
    if (!sweepstakeTheme) {
        throw new Error('Sweepstake theme is invalid');
      }

    const [names, dispatch] = useReducer(nameReducer, []);
    const navigate = useNavigate();
    const loc = useLocation();
    const [formErrors, setFormErrors] = useState([]);
    const numberOfEntries = loc.state.numberOfPeople;
    const title = sweepstakeTheme.headline;

    function handleValidation() {
        let formIsValid = true;
        let formErrors = {};
        const fields = [...Array(Number(numberOfEntries)).keys()].map(ne => {
            const id = Number(ne) + 1;
            return 'Participant' + id;
        })
        fields.forEach(field => {
            let element = findElementById(field);
            if(element === undefined) {
                formErrors[field] = "Enter a name for this Sweeperstaker";
                formIsValid = false;
            } else if(element.text.trim() === "") {
                formErrors[field] = "Enter a name for this Sweeperstaker";
                formIsValid = false;
            } 
            // else if (names.filter(name => name.text === element.text).length > 1) {
            //     formErrors[field] = "More than one Sweeperstaker has the same name";
            // }
        })

        setFormErrors(formErrors);
        return formIsValid;
    }

    function findElementById(field) {
        return names.find(element => {
            return element.id === field;
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!handleValidation()) {
            return;
        }
        const { name } = e.nativeEvent.submitter;
        if (name === 'liveDraw') {
            window.sweepstakeStarted(sweepstakeTheme.headline);
            const nextPath = getNextPathname("START_DRAW");
            sessionStorage.setItem('nextPath', nextPath);
            createPairings(sweepstakeTheme, names)
            navigate(nextPath, {state: {people: names}})
        } else if (name === 'viewResults') {
            window.sweepstakeStarted(sweepstakeTheme.headline);
            const nextPath = getNextPathname("DISPLAY_RESULTS");
            sessionStorage.setItem('nextPath', nextPath);
            const pairings = createPairings(sweepstakeTheme, names)
            navigate(nextPath, {state:  pairings.pairings});
        } else {
            return;
        }

    };

    function handleChangeName(e) {
        dispatch({
            type: 'changed',
            field: e.target.name,
            value: e.target.value,
          });
      }

    function addInput (){
        let inputs = [...Array(Number(numberOfEntries)).keys()]
        return inputs.map((input) => {
            const placeHolderId = Number(input) + 1;
            return (
            <div className='nameFormInput' key={placeHolderId}>
                <input
                    type="text"
                    className='nameInput'
                    name={'Participant' + placeHolderId}
                    placeholder={'Participant ' + placeHolderId}
                    onChange={handleChangeName}
                />
                {
                    <span className='formErrors'>{formErrors['Participant' + placeHolderId]}</span>
                }
            </div>  )
            })
    }
    clearSweepstakeInsessionStorage();
    return (
        <>
            <div className=''>
                <form onSubmit={handleSubmit} className="nameForm">
                    <h1 className='header__nameForm'>{title}</h1>
                    <h2 className='subheader__nameForm'>Enter names:</h2>
                    <div className='inputDiv'>
                        {addInput()}
                    </div>
                    <div class="sweeperFormButtonContainer">
                        <div className='sweeperFormButtonWrapper'>
                            <CustomButton buttonSize='btn--wide--fixed' buttonColor='clear' buttonStyle='btn--border' type="submit" name='liveDraw' testId={"enter-names-button"}>
                                WATCH DRAW
                            </CustomButton>
                        </div>
                        <div className='sweeperFormButtonWrapper'>
                            <CustomButton buttonSize='btn--wide--fixed' buttonColor='clear' buttonStyle='btn--border' name='viewResults' type="submit" >
                                SKIP TO RESULTS
                            </CustomButton>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

function clearSweepstakeInsessionStorage() {
    if (sessionStorage.getItem('sweepstakePairings')) {
        sessionStorage.removeItem('sweepstakePairings');
    }
}

function nameReducer(names, action) {
    if (names && names.some(item => action.field === item.id)) {
        return names.map((t) => {
            if (t.id === action.field) {
                return {
                    id: action.field,
                    text: action.value
                }
              } else {
                return t;
              }
        })
    } else {
        return [
            ...names,
            {
                id: action.field,
                text: action.value
            }
            
        ]
    }
  }