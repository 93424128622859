import React from 'react';
import './startsection.css';

function StartSection({
  timer
  }) {

    return (
      <>
        <div
          className={'start-section'}
        >
          <div className='start-container'>
                <div className='start-text-wrapper'>
                  <h1 className='heading title'>
                    {timer}
                  </h1>
                </div>
          </div>
        </div>
      </>
    );
  }
  

export default StartSection;
