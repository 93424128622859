import React, {useLayoutEffect} from 'react';
import './common-error.css';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../Button/CustomButton';
import { PATH_NAMES } from '../../app.constants';

function NotFoundComponent() {

  let navigate = useNavigate();

  const routeChange = () => {
    navigate(PATH_NAMES.HOME);
  }

  useLayoutEffect(() => {
    document.title = '404 - The Sweepstaker';
   }, []);

  return (
    <div className='not-found-wrapper'>
      <div className='not-found-text-wrapper'>
        <h1 className='not-found-header'>Page Not Found.</h1>
        <h2 className='not-found-sub-header'>The page you were looking for doesn't exist.</h2>
      </div>
      <CustomButton buttonSize='btn--wide' buttonColor='clear' buttonStyle='btn--border' onClick={routeChange} testId={"home-button"}>
                  Back to Home
      </CustomButton>
    </div>
  )};

export default NotFoundComponent;