import React from 'react';
import {Link} from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import './navbar.css';
import { PATH_NAMES } from '../../app.constants';
import LogoSvg from '../../assets/logo_navbar.svg'

function Navbar({handleClick, closeMobileMenu, click}) {

    return (
        <>
        <IconContext.Provider value={{color: '#fff'}}>
           <nav className="navbar">
               <div className="navbar-container">
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        <img src={LogoSvg} alt="thesweepstaker-logo" className='logo-img' loading="lazy"/>
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        {click ? <FaTimes color="black"/> : <FaBars color="black"/>}
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="nav-item">
                            <Link to={PATH_NAMES.HOME}  className="nav-links" onClick={closeMobileMenu}>
                               HOME
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={PATH_NAMES.SWEEPSTAKES} className="nav-links" onClick={closeMobileMenu}>
                               SWEEPSTAKES
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={PATH_NAMES.ABOUT} className="nav-links" onClick={closeMobileMenu}>
                               ABOUT
                            </Link>
                        </li>
                    </ul>
               </div>
            </nav>
            </IconContext.Provider>
        </>
    );
}

export default Navbar
