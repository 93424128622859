import React, { useContext, useLayoutEffect } from 'react'
import {useLocation} from 'react-router-dom';
import { JourneyContext } from '../../JourneyContext';
import GeneratePairings from './GeneratePairings';
import { cleanUpMetaTag, updateMetaTag } from '../../common/metaTagHelper';
import createPairings from '../../common/createPairingsHelper';

export default function LiveDraw() {
  
  useLayoutEffect(() => {
    document.title = 'Live Draw - The Sweepstaker';
    updateMetaTag("robots", "noindex, nofollow")
      return () => {
        cleanUpMetaTag();
      }
   }, []);

  const [ sweepstakeTheme ] = useContext(JourneyContext);
  if (!sweepstakeTheme) {
    throw new Error('Sweepstake theme is invalid');
  }
  const loc = useLocation();

  let sweepstakePairings = sessionStorage.getItem('sweepstakePairings');
  let pairings;
  if (sweepstakePairings) {
    let parsedPairings = JSON.parse(sweepstakePairings);
    pairings = {'pairings': parsedPairings}
  } else {
    pairings = createPairings(sweepstakeTheme, loc.state.people);
  }
  return (
      <GeneratePairings sweepstakes={pairings} />
  )
}