import React, { useState, useContext } from 'react'
import { getNextPathname } from '../../common/constants';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from '../Button/CustomButton';
import './sweepstakes.css';
import { JourneyContext } from '../../JourneyContext';


function EnterNumber({sweepstakeTheme, sweepstakeName}) {
    const navigate = useNavigate();
    const [ , setSweepstakeTheme ] = useContext(JourneyContext);
    const [formError, setFormError] = useState();
    const [numberOfParticipants, setNumberOfParticipants] = useState();
    const maxNumberOfParticipants = sweepstakeTheme.items.length;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!handleValidation()) {
          return;
      }
        setSweepstakeTheme(sweepstakeName);
        window.sweepstakeNumberOfPeopleSelected(sweepstakeTheme.headline, numberOfParticipants);
        const nextPath = getNextPathname("NUMBER_OF_PARTICIPANTS_SELECTED");
        sessionStorage.setItem('nextPath', nextPath);
        navigate(nextPath, {state: {numberOfPeople:  numberOfParticipants}})
    };

    function handleValidation() {
      if(parseInt(numberOfParticipants) > 1 && parseInt(numberOfParticipants) <= maxNumberOfParticipants) {
        return true;
      } else {
          let formError = `The number of participants must be between between 2 and ${maxNumberOfParticipants}`
          setFormError(formError);
          return false;
      }
    }

    return (
        <>
            <div className='enterParticipantsSection'>
                <div className='participantsInput'>
                  <input
                        type="text"
                        inputMode="numeric" 
                        pattern="[0-9]*"
                        id='number-of-participants'
                        className='numberOfParticipantsInput'
                        name='Number of participants'
                        onChange = {(e) => setNumberOfParticipants(e.target.value)}
                    />
                {
                    <span className='participantFormErrors'>{formError}</span>
                }
                </div>
                <div className='participantStartButton'>
                    <CustomButton buttonSize='btn--wide--fixed' buttonColor='clear' buttonStyle='btn--border' testId={"start-sweepstake-button"} onClick={e => handleSubmit(e, maxNumberOfParticipants)}>
                        GET STARTED
                    </CustomButton>
                </div>
            </div>
        </>
    )
}

export default EnterNumber;