import React from 'react'
import InternalServerErrorComponent from './InternalServerErrorComponent';

export class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }

  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <InternalServerErrorComponent/>
      }
  
      return this.props.children; 
    }
  }