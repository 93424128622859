import React, {useState} from 'react';
import { FaCopy, FaCheck } from 'react-icons/fa';
import { WhatsappShareButton, WhatsappIcon, EmailShareButton, EmailIcon } from "react-share";
import './displayresults.css';

function Share({pairings, sweepstakeTheme}) {

    const [resultsCopied, setResultsCopied] = useState(false)

    const handleClick = () => {
        if (!resultsCopied) {
          setResultsCopied(!resultsCopied);
        }
      }

      const getPairingsToShare = (pairings) => {
        return "Hey, I just used The Sweepstaker (https://thesweepstaker.com) to create an " + sweepstakeTheme.headline + " Sweepstake! Check out our results below:\n\n"
        + pairings.map((item) => item.team + ' - ' + item.person + "\n").join("");
       }
   
       const getEmailSubject = () => {
         return "The Sweepstaker - " + sweepstakeTheme.headline + " sweepstake results";
       }

    return (
        <>
            <div className='shareSection'>
                <h2 className='resultsHeading'>Share the results</h2>
                <div className='shareButtonSection'>
                    <div className='clipboardButtonWrapper'>
                        {resultsCopied ? 
                            <div className='clipboardCopiedButton'>
                                <p className='clipboardText'>Copy Results</p>
                                <FaCheck size={50}/>
                            </div> 
                            :
                            <div>
                                <div className='clipboardShareButton' onClick={handleClick}>
                                    <p className='clipboardText'>Copy Results</p>
                                    <FaCopy cursor={"pointer"} size={50}
                                        onClick={() => navigator.clipboard.writeText(getPairingsToShare(pairings))}/> 
                                </div>
                            </div>}
                    </div>
                    <div className='whatsappButtonWrapper'>
                        <WhatsappShareButton className="whatsappShareButton"  url={getPairingsToShare(pairings)}>
                            <WhatsappIcon round={false} size={50} borderRadius={15}/>
                        </WhatsappShareButton>
                    </div>
                    <div className='emailButtonWrapper'>
                        <EmailShareButton className="emailShareButton" subject={getEmailSubject} url={getPairingsToShare(pairings)}>
                            <EmailIcon size={50} round={false} borderRadius={15}/>
                        </EmailShareButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Share;
