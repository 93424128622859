export const PATH_NAMES = {
    HOME: "/",
    THANKS: "/thanks",
    SWEEPSTAKES: "/sweepstakes",
    ENTER_SWEEPERS: "enter-sweepers",
    LIVE_DRAW: "live-draw",
    DISPLAY_RESULTS: "display-results",
    NUMBER_OF_PARTICIPANTS: "number-of-participants",
    ABOUT: "/about",
    PRIVACY: "/privacy",
    COOKIES: "/cookies",
    TERMS: "/terms",
    EURO_2024: "euro-2024",
    WOMENS_T20_WORLD_CUP_2024: "womens-t20-cricket-world-cup-2024",
    FORMULA_ONE_DRIVERS_2024: "formula1-drivers-championship-2024",
    FORMULA_ONE_CONSTRUCTORS_2024: "formula1-constructors-championship-2024",
    JOSHUA_DUBOIS_BOXING: "anthony-joshua-daniel-dubois-boxing-sweepstake",
    TOUR_DE_FRANCE_2024: "tour-de-france-femmes-2024"
}