import React, { useLayoutEffect } from 'react';
import "./cookies.css";
import { cleanUpMetaTag, updateMetaTag } from '../../common/metaTagHelper';

export default function Cookies() {

    useLayoutEffect(() => {
        document.title = 'Cookies - The Sweepstaker';
        updateMetaTag("description", "The Sweepstaker's Cookie Policy")
        updateMetaTag("keywords", "Cookie Policy, Cookies, The Sweepstaker");
          return () => {
            cleanUpMetaTag();
          }
       }, []);
  
    return (
                <div className='cookies'>
                    <h1 className='cookiesHeader'>Cookies</h1>
                    <div className='cookies__container'>
                        <div className='cookies__section'>
                        <h2 className='cookiesSubHeader'>What Are Cookies?</h2>
                        <p>Cookies are tiny files that are downloaded to your computer or mobile device, to improve your experience on this website.</p>
                        </div>
                        <div className='cookies__section'>
                            <h2 className='cookiesSubHeader'>How We Use Cookies</h2>
                            <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</p>
                        </div>
                        <div className='cookies__section'>
                            <h2 className='cookiesSubHeader'>Disabling Cookies</h2>
                            <p>You can prevent the setting of cookies by adjusting the settings on your browser. Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.</p>
                        </div>
                        <div className='cookies__section'>
                            <h2 className='cookiesSubHeader'>What Cookies do we use?</h2>
                            <p>There are many different types of Cookies but currently we only use Analytics Cookies for helping us understand how the site is used which will allow us to make the most useful improvements to it.</p>
                            <p>This site uses Google Analytics for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.</p>
                            <p>For further details on how Google processes this data, please visit <a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a></p>
                        </div>
                    </div>
                </div>
                        
    );
}