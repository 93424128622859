import React from 'react';
import './button.css';

const STYLES = ['btn--primary', 'btn--outline', 'btn--border'];

const SIZES = ['btn--medium', 'btn--large', 'btn--mobile', 'btn--wide', 'btn--wide--fixed'];

const COLOR = ['primary', 'blue', 'red', 'green', 'grey', 'teal', 'clear'];

export const CustomButton = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonColor,
  value,
  testId,
  name
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  const checkButtonColor = COLOR.includes(buttonColor) ? buttonColor : null;

  return (
    <button 
      data-testid={testId}
      className={`btn ${checkButtonStyle} ${checkButtonSize} ${checkButtonColor}`}
      onClick={onClick}
      type={type}
      name={name}
      value={value}>
      {children}
    </button>
  );
};
