import FormulaOneImage from '../../assets/formula-1-driver-celebrating.jpg'
import { USER_EVENTS } from '../../stateMachine/StateMachine'

export const f1SeasonDrivers2024Constants = {
    lightText: true,
    topLine: 'F1 Drivers World Championship 2024 sweepstake',
    headline: 'F1 Drivers World Championship 2024',
    subText: "Which F1 driver will win the 2024 Driver's Championship?",
    metaDescription: "Gear up for the new F1 season with a free F1 Drivers sweepstake from The Sweepstaker. Predict which driver will come out on top and join the excitement with friends and family!",
    metaKeywords: "F1 Drivers Championship 2024, F1, F1 Drivers, sweepstake, free, Formula One, Formula One sweepstake, The Sweepstaker",
    sweepstakeSelectedEvent: USER_EVENTS.F1_DRIVERS_2024_SELECTED,
    startDate: '2024/03/02',
    endDate: '2024/12/08',
    img: FormulaOneImage,
    category: "formulaOne",
    alt: 'A racing driving celebrating with a trophy',
    items: [
    "Max Verstappen",
	"Sergio Perez", 
    "Charles Leclerc", 
    "Carlos Sainz",
    "Lewis Hamilton",	
    "George Russell",
    "Esteban Ocon",
    "Pierre Gasly",
    "Lando Norris",
    "Oscar Piastri",
    "Valtteri Bottas",
    "Zhou Guanyu",
    "Kevin Magnussen",
    "Nico Hulkenberg",
    "Fernando Alonso",
    "Lance Stroll",
    "Yuki Tsunoda",
    "Daniel Ricciardo",
    "Alexander Albon",
    "Logan Sargeant"
    ]
}