import React, {useState} from 'react';
import Navbar from '../Navbar/Navbar';
import './backgroundsection.css';
import Footer from '../Footer/Footer';
import CookieConsent from "react-cookie-consent";
import { ErrorBoundary } from '../Errors/ErrorBoundary';


function BackgroundSection({childComponent}) {
    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)


  const onAccept = () => {
    window.consentAnalyticStorage();
    };

    return (
        <>
            <div className='background__section'>
                <Navbar closeMobileMenu={closeMobileMenu} handleClick={handleClick} click={click}/>
                    <div className={click ? 'hide__child__component' : 'child__component'}>
                        <ErrorBoundary>
                            {childComponent}
                        </ErrorBoundary>
                    </div>
                    <div className={click ? 'increase_height_footer' : 'standard_height_footer'}>
                        <Footer closeMobileMenu={closeMobileMenu}/>
                        <div className='cookieConsentWrapper'>
                            <CookieConsent
                            buttonText="Accept"
                            declineButtonText="Reject"
                            enableDeclineButton
                            cookieName="ss_cc"
                            flipButtons
                            disableStyles={false}
                            style={{ background: "white", color: "black" }}
                            buttonStyle={{background: "rgb(0, 60, 223)", color: "white", borderRadius: "4px", borderColor: "rgb(0, 60, 223)", width: "200px"}}
                            declineButtonStyle={{background: "rgb(0, 60, 223)", color: "white", borderColor: "rgb(0, 60, 223)" , borderRadius: "4px", height: "50px", width: "200px"}}
                            buttonClasses="btn btn-primary"
                            buttonWrapperClasses="consentButtons"
                            containerClasses="alert alert-warning col-lg-12"
                            contentClasses="text-capitalize"
                            onAccept={() => onAccept(true)}>We use cookies to give you the best experience of our site. For more information see our <a href="/cookies">Cookie Policy</a></CookieConsent>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default BackgroundSection;